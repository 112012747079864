$(document).ready(function(){

    $(document).on('click','#register', function() {
        $('#selectTypeRegisterModal').modal('show');
    });

    $(document).on('click','.btn-close-modal', function() {
        $('#selectTypeRegisterModal').modal('hide');
    });

});