$(window).scroll(function(){
    if($(window).width() >= 1350) {
        var sticky = $('#menu'),
            scroll = $(window).scrollTop();

        if (scroll >= 100) {
            sticky.addClass('header-fixed');
            $('.nav-link').addClass('color-white');
        }
        else {
            sticky.removeClass('header-fixed');
            $('.nav-link').removeClass('color-white');
        }
    }
});

var shoppingBag = $("#shopping-bag");
var boxCartDetail = $('.box-cart-detail');
var btnCart = $('.btn-dropdown-cart');
var boxLanguage = $('.box-language');
var menuLanguage = $('#menu-language');

shoppingBag.on("mouseenter", function(e) {
    $('.box-cart-detail').css('display', 'block');
});

btnCart.on("mouseenter", function(e) {
    $('.box-cart-detail').css('display', 'block');
});

boxCartDetail.on("mouseenter", function(e) {
    $('.box-cart-detail').css('display', 'block');
});

boxCartDetail.on("mouseleave", function(e) {
    $('.box-cart-detail').css('display', 'none');
});

shoppingBag.on("mouseleave", function(e) {
    $('.box-cart-detail').css('display', 'none');
});

boxLanguage.on("mouseenter", function(e) {
    $('#dropdownLanguage').trigger('click');
});

menuLanguage.on("mouseenter", function(e) {
    $('#dropdownLanguage').trigger('click');
});

menuLanguage.on("mouseleave", function(e) {
    $('#dropdownLanguage').trigger('click');
});

function searchProduct() {
    $('#item-search').submit()
}
