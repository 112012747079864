function Cart() {
}

Cart.fn = {
    init: function () {
        Cart.fn.handle.call();
        Cart.fn.changeTransportType.call();
        Cart.fn.changeQuantity.call();
        Cart.fn.dependScan.call();
        Cart.fn.dependEvent.call();
    },

    dependScan: function(_this) {
        $('.show-depend').map(function () {
            Cart.fn.dependProcess($(this));
        });
    },

    dependEvent: function() {
        $(document).on('change', '.show-depend', function () {
            Cart.fn.dependProcess($(this));
        });
    },

    dependProcess: function(_this) {
        var dependField = _this.data('depend_field');
        var dependCond = _this.data('depend_cond');
        var val = $('[name="'+ _this.attr('name') +'"]:checked').val();

        if(val == dependCond) {
            $(dependField).removeAttr('disabled').show();
        } else {
            $(dependField).attr('disabled', 'disabled').hide();
        }
    },

    handle: function() {
        var initial;

        $(document).on('click', '.quality-product .action', function () {
            clearTimeout(initial);

            var _this = $(this);
            var id = _this.attr('id');
            var input = _this.closest('.quality-product').find('input.quantity');
            var minQty = parseInt(input.attr('data-min-qty'));

            if(minQty < 0) {
                minQty = 1;
            }

            var val = parseInt(input.val());
            var cartBody = _this.closest('.cart-body');

            if(id == 'remove') {
                _this.closest('.row').remove();
            } else if(id == 'decrease') {
                input.val(val > minQty ? val - minQty : 0);
            } else {
                input.val(val > 0 ? val + minQty : minQty);
            }

            if(cartBody.length) {
                if(id == 'decrease' || id == 'increase') {
                    initial = window.setTimeout(function() {
                        input.trigger('change');
                    }, 1000);
                } else {
                    cartBody.closest('form').submit();
                }
            }
        });
    },

    changeTransportType: function() {
        $(document).on('change', '.cart-body input[name="transport_type"]', function () {
            $(this).closest('form').submit();
        });
    },

    changeQuantity: function() {
        $(document).on('change', '.cart-body .quality-product .quantity', function () {
            $(this).closest('form').submit();
        });
    },
    
    rule: function () {
        $(document).ready(function () {
            Cart.fn.init.call(this);
        });
    }
};

Cart.fn.rule();