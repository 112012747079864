function MainWeb() {
}

MainWeb.fn = {
    init: function () {
        MainWeb.fn.changeUserPhoto.call();
        //MainWeb.fn.addRoom.call(this);
        //MainWeb.fn.dateTimePicker.call(this);
        MainWeb.fn.proofOfPurChaseChange.call(this);
    },

    proofOfPurChaseChange: function () {
        $('#proof_of_purchase').on('change', function () {
            var file = $('#proof_of_purchase')[0].files[0].name;
            if (file) {
                document.getElementById("input-label").value = file;
            }
        });
    },

    changeUserPhoto: function () {
        $('#who-is-who-photo button').on('click', function () { 
            alert('halo');
            $('#img-upload-photo').trigger('click'); 
        });

        $("#img-upload-photo").on('change', function () {
            changeImage(this);
        });
    },

    rule: function () {
        $(document).ready(function () {
            MainWeb.fn.init.call(this);
        });
    }
};

MainWeb.fn.rule();

function changeImage(e) {
    if (e.files && e.files[0]) {
        var name = e.getAttribute('name');
        var reader = new FileReader();
        reader.onload = function (e) {
            $('.form-change #img-' + name)
                .attr('src', e.target.result);
        };

        reader.readAsDataURL(e.files[0]);
    }
}